.color-game-div {
  /* height: 89vh; */
  /* width: 50%; */
  overflow-y: scroll;
}

.color-game-div::-webkit-scrollbar {
  display: none;
}
.grebtn{
  background-color: green !important;
  font-size: 19px !important;
}
.grebtn1{
  background-color: red !important;
  font-size: 19px !important;
}
.color-game-h {
  background-color: #2c3d51;
  width: 100%;
  height: 39px;
}
.dis{
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 600px) {
  .btas {
    background-image: linear-gradient(
      to right,
      #3d8b13 0%,
      #25c321 51%,
      #6c7b65 100%);
    border: none;
    width: 130px !important;
    margin-left: auto !important;
    margin-bottom: 10px !important;
    margin-right: auto !important;
    text-align: center;
    justify-content: center;
    box-shadow: inset 0px 0px 2px 2px #00000063;
    margin-right: 10px;
    padding: 10px 5px;
    border-radius: 10px;
  }
  .btas1 {
    background: red;
    font-size: 25px;
    border: 1px solid white;
    width: 130px !important;
    color: white;
    margin-right: auto;
    margin-left: auto;
    display: block;
    margin-right: 68px;
    padding: 10px 5px;
    border-radius: 10px;
  }
  .voilet{
    background: linear-gradient(to right bottom, rgb(141 77 181) 50%, rgb(227 44 39) 50%) !important;
  
  }
  .vt{
    background: linear-gradient(to right bottom, rgb(141 77 181) 50%, rgb(47 177 29) 50%) !important;
  }
  .small-red-btn {
    background-color: red;
    border: 1px solid white;
    height: 47px;
    width: 80px;
    margin-right: 5px !important;
    border-radius: 10px;
    color: white;
    font-family: "mukta";
    font-weight: bold;
    font-size: 30px;
}
  .small-green-btn {
    background-color: green;
    border: 1px solid white;
    height: 47px;
    width: 80px;
    margin-right: 5px !important;
    border-radius: 10px;
    color: white;
    font-family: "mukta";
    font-weight: bold;
    font-size: 30px;
  }
  .violet-btn {
    background: rgb(141 77 181);
    border: 1px solid white;
    height: 47px;
    width: 80px;
    
    border-radius: 10px;
    margin-right: 5px !important;
    color: white;
    font-family: "mukta";
    font-weight: bold;
    font-size: 30px;
}
.violet-button{
  width: 250px !important;
  width: 87px !important;
  padding: 5px 5px !important;
  border: 1px solid white !important;
  font-size: 20px !important;
}
.red-button{
  width: 250px !important;
  width: 87px !important;
  padding: 5px 5px !important;
  border: 1px solid white !important;
  font-size: 20px !important;
}
  .dis {
    display: flex;
    justify-content: space-around !important;
   
    text-align: center;
}
.Green-button {
  background: green;
  /* box-shadow: rgb(0 194 130 / 40%) 0px 0px 8px; */
  
  margin: 5px;
  border: 1;
  
  text-align: center;
  height: 55px;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  /* box-shadow: 0 0 20px #eee; */
  border-radius: 3px;
  display: block;
  margin-right: auto;
  margin-left: auto;
  border: none;
  font-family: "mukta";
  font-weight: bolder;
 
  width: 87px !important;
  padding: 5px 5px !important;
  border: 1px solid white !important;
  font-size: 20px !important;
}
}

.voilet{
  background: linear-gradient(to right bottom, rgb(141 77 181) 50%, red 50%) !important;

}
.vt{
  background: linear-gradient(to right bottom, rgb(141 77 181) 50%, green 50%) !important;
}
.btas {
  background: green;
  font-size: 30px;
  border: 1px solid white;
  width: 130px;
  color: white;
  margin-right: 10px;
  margin-right: auto;
    margin-left: 68px;
    display: block;
    padding: 5px 5px;
    border-radius: 6px;
  font-family: "mukta";
    font-weight: bolder;
}

.btas1 {
  background: red;
  font-size: 30px;
  border: 1px solid white;
  width: 130px;
  color: white;
  margin-right: auto;
    margin-left: auto;
    display: block;
  margin-right: 68px;
  padding: 5px 5px;
  border-radius: 6px;
  font-family: "mukta";
    font-weight: bolder;
}
.color-game-h p {
  color: white;
  font-family: "mukta";
  font-size: 22px;
  font-weight: 500;
}

.color-game-card {
  height: 300px;
  width: 100%;
  border-radius: 6px;
  background-color: rgb(206, 206, 221);
}

.Green-button {
  background: green;
  /* box-shadow: rgb(0 194 130 / 40%) 0px 0px 8px; */
  border: 1px solid white !important;
  font-size: 30px;
  margin: 5px;
  border: 1;
  padding: 5px 30px;
  text-align: center;
  height: 55px;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  /* box-shadow: 0 0 20px #eee; */
  border-radius: 3px;
  width: 155px;
  display: block;
  margin-right: auto;
  margin-left: auto;
  border: none;
  font-family: "mukta";
  font-weight: bolder;
}

.violet-button {
  background-image: linear-gradient(
    to right,
    #9d50bb 0%,
    #6e48aa 51%,
    #9d50bb 100%
  );
  /* box-shadow: rgb(155 39 176 / 40%) 0px 0px 8px; */
  border: 1px solid white !important;
  font-size: 30px;
  margin: 5px;
  border: 1;
  padding: 5px 30px;
  text-align: center;
  height: 55px;
  transition: 0.5s;
  background-size: 200% auto;
  color: rgb(255, 255, 255);
  /* box-shadow: 0 0 20px #eee; */
  border-radius: 3px;
  display: block;
  margin-right: auto;
  margin-left: auto;
  width: 155px;
  border: none;
  font-family: "mukta";
  font-weight: bolder;
}

.red-button {
  background:red;
  /* box-shadow: rgb(0 194 130 / 40%) 0px 0px 8px; */
  border: 1px solid white !important;
  font-size: 30px;
  width: 155px;
  margin: 5px;
  border: 1;
  padding: 5px 30px;
  text-align: center;
  height: 55px;
  transition: 0.5s;
  background-size: 200% auto;
  color: rgb(255, 255, 255);
  /* box-shadow: 0 0 20px #eee; */
  border-radius: 3px;
  display: block;
  margin-right: auto;
  margin-left: auto;
  border: none;
  font-family: "mukta";
  font-weight: bolder;
}

.paymettext1 {
  padding-left: 10px;
}

.labels {
  color: white;
  font-size: 15px;
}

.violet-red-btn {
  background: linear-gradient(
    to right bottom,
    rgb(141 77 181) 50%,
    rgb(227 44 39) 50%
  );
  border: none;
  height: 47px;
  width: 70px;
  border-radius: 10px;
  color: white;
  font-family: "mukta";
  font-weight: bold;
  font-size: 20px;
}

.t-2 {
  padding-top: 10px;
}

.maindis {
  display: flex;
  justify-content: space-between;
}

.violet-btn {
  background: rgb(141 77 181);
  border: 1px solid white;
  height: 47px;
  width: 80px;
  border-radius: 10px;
  color: white;
  font-family: "mukta";
  font-weight: bold;
  font-size: 30px;
}

.small-green-btn {
  background-color: green;
  border: 1px solid white;
  height: 47px;
  width: 80px;
  border-radius: 10px;
  color: white;
  font-family: "mukta";
  font-weight: bold;
  font-size: 30px;
}

.small-red-btn {
  background-color: red;
  border: 1px solid white;
  height: 47px;
  width: 80px;
  border-radius: 10px;
  color: white;
  font-family: "mukta";
  font-weight: bold;
  font-size: 30px;
}

.violet-green-btn {
  background: linear-gradient(
    to right bottom,
    rgb(141 77 181) 50%,
    rgb(47 177 29) 50%
  );
  border: none;
  height: 47px;
  width: 70px;
  border-radius: 10px;
  color: white;
  font-family: "mukta";
  font-weight: bold;
  font-size: 20px;
}

.table-h-div {
  background-color: #e4e4e4;
  width: 100%;
  height: 37px;
  color: #243a48;
  font: 13px;
  font-family: "mukta";
  font-weight: bold;
}

.table-inner-row {
  background-color: white;
  width: 100%;
  height: 58px;
  color: #243a48;
  text-align: center;
  font-size: 20px;
  font-family: "mukta";
  font-weight: 500;
  border-bottom: 1px solid #b0b5b8;
}
.table-overflow {  
  max-height: 400px;
  overflow-y: scroll;
}

.red-dot {
  width: 20px;
  height: 20px;
  background-color: #e32e27;
  border-radius: 10px;
}

.violet-dot {
  width: 20px;
  height: 20px;
  background-color: rgb(141 77 181);
  border-radius: 10px;
}

.green-dot {
  width: 20px;
  height: 20px;
  background-color: #25c321;
  border-radius: 10px;
}

.plus-icon {
  height: 20px;
  width: 25px;
  cursor: pointer;
  
}
.card2 {
  background-color: white !important;
}

.fail {
  color: rgb(235, 44, 44) !important;
}

.success {
  color: #25c321 !important;
}
.default {
  color: #2c3d51 !important;
  
}

.bet-menu-card {
  width: 100%;
  height: 370px;
  color: #243a48;
  font-size: 17px;
  font-family: "mukta";
  font-weight: 500;
}

.bet-menu-card-h {
  color: #0ecf9f;
  font-size: 18px;
  font-family: "mukta";
}

/* <<<<<<<<<<<<<<------------------------------ModalData-------------->>>>>>> */
.color-modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: none;
}

.color-modal-container {
  position: absolute;
  width: 520px;
  height: 370px;
  /* background: linear-gradient(105deg, #7D2DE6 0%, #7E2EE7 40%, #7C2CE5 100%); */
  background-color: #01a839;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* border-radius: 8px; */
  z-index: 999;
}

.model-h-dive {
  background-color: #cfd5d9;
  font-size: 17px;
  font-weight: bold;
  height: 43px;
}

.num-box {
  height: 40px;
  width: 80px;
  border: none;
  color: #9e9a9a;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  background: white;
  /* background-color: rgb(165, 152, 152); */
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
}

.num-box.is-activated {
  background-color: #9e9a9a !important;
  color: black;
}

.minus-div {
  width: 50px;
  height: 45px;
  border: none;
  color: #9e9a9a;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  background: white;
  font-size: 20px;
}

.plus-div {
  width: 50px;
  height: 45px;
  border: none;
  color: #9e9a9a;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  background: white;
  font-size: 20px;
}

.counter-data-div {
  width: 200px;
  height: 45px;
  color: black;
  font-size: 20px;
}

@media screen and (max-width: 500px) {
  .color-game-h p {
    font-size: 17px;
  }

  .color-modal-container {
    position: absolute;
    width: 400px;
    height: 370px;
    /* background: linear-gradient(105deg, #7D2DE6 0%, #7E2EE7 40%, #7C2CE5 100%); */
    /* background-color: #01a839; */
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* border-radius: 8px; */
    z-index: 999;
  }
}

.innercontent {
  margin: 20px;
  border: 0.1rem solid White;
}

.innerheading {
  border-bottom: 0.1rem solid white;
}

.borderintop {
  padding: 8px 10px 0px;
  border-bottom: 0.1rem solid white;
}

.paymettext {
  border-right: 0.1rem solid white;
}

.modallist {
  border-top: 0.1rem solid white;

  /* gap: 0.3rem; */
  overflow: auto;
  white-space: nowrap;
}
.loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 17px;
  font-weight: 700;
}

.loader-container {
  position: absolute;
  z-index: 1000;
  background-color: #000000de;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wsuccess {
  color: #01a839 !important;
}
.ldanger {
  color: red !important;
}
.betes{
  width: 140px !important;
  text-align: center !important;
}
.tableColor {
  background-color: navy !important;
  color:  #2c3d51 !important;
}
.tablheader {
  background-color: #2c3d51 !important;
  color: white !important;
}
