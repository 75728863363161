.L-sidebar{
    background-color:transparent;
    height: 100%;
    /* width: 20%; */
    overflow-y: scroll;
}
.L-sidebar::-webkit-scrollbar{
    display:none;
}
.maincard-div{
    border-radius: 7px;
    background-color: #b92e2e;
    height: 250px;
    width:95%;
}
.meddle{
    text-align: center;
    color: red !important;
    font-size: 20px !important;
    border-bottom: 2px solid green;
}
.bg-transparent{
    margin-top: 20px;
    /* background: rgb(51 112 27) !important; */
    border: 2px solid rgb(19 148 13) !important;
    background: rgb(8 15 37) !important;
    border-Radius: 15px;
    margin-Top:10px
}
.bgsss{
    background-color: rgb(27 93 31) !important;
    width: 93%;
}
.leftbar-h{
   
    border-bottom: 1px solid #43CEED;

}
.leftbar-h p{
    font-size: 15px;
    color:#43CEED;
    font-family: "mukta";

}
.maincard{
    height:250px;
    background-color: #202020;
    
    border-radius: 7px;
}
.overlay{
    background-color: rgba(0, 0, 0, 0.5); /* Adjust the alpha value (0.5) to change the transparency */
    height: 100%;
    width: 95%;
    position: absolute;
    border-radius: 10px;
    
}
.overlay h2{
    z-index: 999;
    color: white !important;
    font-family: "mukta ";

    
}


.card-img-div{
    width: 240px;
    height:140px !important;
    margin-left: 45px;

}
.maincard-btn{
    height: 30px;
    width: 80px;
    border-radius: 6px;
    border:none !important;
    color: white;
    font-size: 16px;
    background: linear-gradient(to right, #1368de 0, #4a8ff0 15%, #548ee0 30%, #1567d8 55%, #2869c3 100%) !important;
}
.slick-dots{
    margin-bottom: 40px;
    color:#fd740d !important;
    /* margin-left: 10px; */
    
  }
  .smallcard-div{
    width: 170px;
    background-color: #202020;
    border-radius: 5px;
    border:none;
    
  }
 
  .small-card-img{
    border-radius: 5px;
  }
  .overlaySmallCard{
    position:absolute;
    bottom:0;
    overflow: hidden;
    left:0;
    right:0;
    height:0;
    background-color: rgba(70, 67, 67, 0.75);
    border-radius: 5px;

  
}
.overlaySmallCard p{
    color:#fd740d;
    font: "mukta";
    font-size: 12px;
    font-weight: 600;
    /* margin-left: 36px; */
    margin-top: 30px;
}


.smallcard-div:hover{
    
    /* translate: 0px 15px 0px; */
    transition: .5s ease;
}
.smallcard-div:hover .overlaySmallCard{
    height:100%;
    width:100%;
    border-radius: 5px;


}
.aviator-card{
    border-radius: 8px;
    width: 360px;
    border: none;
    
    

  }
  .aviator-card-img{
    border-radius: 8px;
  }
  .overlayAviatorCard{
    position:absolute;
    bottom:0;
    overflow: hidden;
    left:0;
    right:0;
    height:0;
    background-color: rgba(70, 67, 67, 0.75);
    border-radius: 5px;
  }
  .overlayAviatorcard-p{
    color:#fd740d;
    font: "mukta";
    font-size: 12px;
    font-weight: 600;
    margin-top: 80px;
  }
  .aviator-card:hover{
    transition: .5s ease;
}
.aviator-card:hover .overlayAviatorCard{
    height:100%;
    width:100%;
    border-radius: 7px;

}

.leftsidecontent .topcard {
    background: linear-gradient(45deg, rgba(3, 23, 21, 0.3411764706), rgba(3, 23, 21, 0.3098039216)) !important;
    /* background-color: #000 !important; */
    transition: all 0.3s ease-in-out;
    border: 2px solid #fd740d !important;
}

.leftsidecontent .topcard.profilcard .card_profileimg {
    width: 60px;
    height: 60px;
    margin: auto;
    border-radius: 50%;
    box-shadow: 0 0 0px 6px #fd740d;
}

.leftsidecontent .topcard.profilcard .userid {
    font-size: 18px !;
    font-weight: 600 !;
    color: #fff !;
    text-align: center !;
}