
.background-img {
  background: url(../../Media/login.jpg);
  background-size:auto;
  width: 100vw;
  height: 100vh;
  position: absolute;
  /* background-size: 100% 100%; */
  /* background-repeat: no-repeat; */
  /* position: relative; */
  z-index:99;
  overflow-y: scroll;
}
.PrivateSwitchBase-input{
    color: white !important;
    background-color: white !important;

}
.img-div{
    height: 71px;
    width: 220px;


}
.css-i4bv87-MuiSvgIcon-root {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 1em;
    height: 1em;
    display: inline-block;
    fill: white !important;
    color: #fff;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-size: 1.5rem;}

    .css-vubbuv {
        user-select: none;
        width: 1em;
        height: 1em;
        display: inline-block;
        fill: white !important;
        flex-shrink: 0;
        transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        font-size: 1.5rem;
    } 

.disp1{
    display: flex;
    justify-content: space-between;
    /* padding: 5px 30px; */
    }
.disp{
display: flex;
justify-content: space-between;
padding: 5px 30px;
}
.login-card1{
    /* height: 420px; */
    width: 360px;
    background-color:#080f25!important;
    position: absolute;
    z-index:99999;
    top: 6%;
    box-shadow: inset 0px 0px 6px 4px #fff !important;

}

.login-card{
    height: 420px;
    width: 318px;
    background-color:#1b5d1f;
    position: absolute;
    z-index:99999;
    top: 15%;

}
.login-card form{
    color: white;
}
.login-input{
    outline: none !important;
    border: none !important;
    background-color:rgb(29, 25, 25) ;
    height: 35px;
    width: 100%;
    border-radius: 5px;
    color: white !important;

}
a {
    color: #0afffa !important;
    text-decoration: underline;
}
.login-btn{
    width: 100%;
    background-color: #009B2f;
    color: white;
    font-family: "mukta";
}
.login-btn:hover{
    background-color:#fa0404 !important;
}
.bth{
    color: white;
    font-size: 15px;
}
.bth span{
    color:#fa5004;
    
}

.texterr{
    color:#fa5004;

}

@media only screen and (max-width: 600px) {
.login-card1{
    /* height: 420px; */
    width: 300px !important;
    background-color:#080f25!important;
    position: absolute;
    z-index:99999;
    top: 6%;
    box-shadow: inset 0px 0px 6px 4px #fff !important;

}
.disp {
    display: flex;
    justify-content: space-between;
    padding: 5px 10px !important;
}
}