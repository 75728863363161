
.background-img {
    background: url(../../Media/login_bg4.jpg);
    background-size:auto;
    width: 100vw;
    height: 100vh;
    /* background-size: 100% 100%; */
    /* background-repeat: no-repeat; */
    position: absolute;
    overflow: hidden;
    z-index:99;
  }
  .img-div{
    height: 71px;
    width: 220px;

  
  }
  
  @media only screen and (max-width: 600px) {
    .wallet-card{
        /* height: 300px;
        width: 400px; */
        background-color: rgb(17, 16, 16);
        /* margin-top: 400px; */
        /* position: absolute; */
        z-index:9999;
        top:0%;
        overflow: scroll;
       
    }
    .space {
        margin-right: 0px !important;
    
  }
  .mtop{
    margin-top: 15px;
  }
  .otps {
    padding: 8px;
    width: 100% !important;
}
.otpss{
    padding: 8px;
    width: 100% !important;
  }
}

a {
  color: #0afffa !important;
  text-decoration: underline;
}
  .wallet-card{
      /* height: 300px;
      width: 400px; */
      background-color: #080f25!important;
      /* margin-top: 400px; */
      /* position: absolute; */
      z-index:9999;
      top:0%;
      overflow: scroll;
      
      border-radius: 20px;
    box-shadow: inset 0px 0px 9px 4px #ffffff;
    padding: 10px 20px;
      
  }
  .otps{
    padding: 8px;
    width: 96%;
  }
  .otpss{
    /* padding: 8px; */
    width: 96%;
  }
  .space{
    margin-right: 10px;
  }
  .wallet-card form{
      color: white;
  }
  .login-input{
      outline: none !important;
      border: none !important;
      background-color:rgb(29, 25, 25) ;
      height: 35px;
      width: 100%;
      border-radius: 5px;
      color: white !important;
  
  }
  .login-btn{
      width: 100%;
      background-color: #009B2f;
      color: white;
      font-family: "mukta";
  }
  .login-btn:hover{
      background-color:#fa5004 ;
  }
  .bth{
      color: white;
      font-size: 9px;
  }
  .bth span{
      color:#fa5004;
      
  }
  


  .css-10drtbx-MuiButtonBase-root-MuiCheckbox-root {
    color: rgb(255, 255, 255) !important;
  }