.Pbox{
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #ffeee7;
    /* background-image: linear-gradient(to bottom right, #686868, #a1a1a1); */
    border-radius: 10px;
    border: 2px solid green !important;
    margin-bottom: 30px;
    margin: 10px;
    box-shadow: 0 0.46875rem 2.1875rem rgba(90,97,105,.1), 0 0.9375rem 1.40625rem rgba(90,97,105,.1), 0 0.25rem 0.53125rem rgba(90,97,105,.12), 0 0.125rem 0.1875rem rgba(90,97,105,.1);
}
.fle{
    display: flex;
    justify-content: space-between;
}
/* .btes{
    margin-top: 29px !important;
} */
.cp-card {
    border: 1px solid green !important;
}
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    font: inherit;
    letter-spacing: inherit;
    color: rgb(0, 0, 0) !important;
    padding: 4px 0 5px;
    border-radius: 10px !important;
    border: 1px solid white !important;
    border: 0;
    box-sizing: content-box;
    background: none;
    height: 1.4375em;
    margin: 0;
    -webkit-tap-highlight-color: transparent;
    display: block;
    min-width: 0;
    width: 100%;
    -webkit-animation-name: mui-auto-fill-cancel;
    animation-name: mui-auto-fill-cancel;
    -webkit-animation-duration: 10ms;
    animation-duration: 10ms;
    padding: 16.5px 14px;
}

.float-right {
    float: right !important;
    color: white !important;
}


.css-heg063-MuiTabs-flexContainer {
    display: -webkit-box;
    display: -webkit-flex;
    overflow: scroll;
    display: -ms-flexbox;
    display: flex;
}
/* .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    font: inherit;
    color: white !important;
    width: 100%;
    letter-spacing: inherit;} */