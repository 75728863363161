nav{
    border-bottom: 1px solid #fd740d !important;
    background-color: #080f25 !important;
    border-bottom: 2px solid green !important;
  }
  @media only screen and (max-width: 600px) {
    .navbar{
    height:13vh !important;
    background: #080f25!important;
    border-bottom: 2px solid green !important;
  }
}
  .navbar{
    height:11vh;
    background: #080f25!important;
  }
  .navbar-brand{
    height: 70px;
  }
  .signin-btn{
    /* padding: 6px 20px; */
    height: 36px;
    width: 90px;
    border-radius: 6px;
    border:none !important;
    color: white;
    font-size: 16px;
    background: linear-gradient(to right, #1368de 0, #4a8ff0 15%, #548ee0 30%, #1567d8 55%, #2869c3 100%) !important;
  }
  .wallet-btn{
    height:36px;
    width: 140px;
    border-radius: 6px;
    background: linear-gradient(to right, #fa5004 0%, #fd740d 51%, #fa5004 100%);
    color: white; 
    border:none;

  }
  .nav-btn{
    /* background-color: rgb(82, 81, 81); */
    background-color: #333333;
    padding: 6px 20px;
    border-radius: 6px;
    border:none !important;
    color: white;
    font-size: 13px;
    margin-left: 4px;
    font-family: "mukta";
    font-weight: 600;
    text-decoration: none;
    
  }
  .nav-btn:hover{
    background: linear-gradient(to right, #fa5004 0%, #fd740d 51%, #fa5004 100%);
  }
  .nav-btn.is-active{
    background: linear-gradient(to right, #fa5004 0%, #fd740d 51%, #fa5004 100%);
  }

  

  /* Dropdown Button */
.dropbtn {
  background-color: transparent;
  color: white;
  padding: 10px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  
}


.dropbtn:hover, .dropbtn:focus {

}


.dropdown {
  position: relative;
  display: inline-block;
  
}


.dropdown-content {
  display: none;
  position: absolute;
 right: 30%;
  background-color: #171744; 
  min-width: 170px;
  /* height: 70px; */
  font-size: 16px;
  font-weight: 600px;
  /* height: 20px; */

  /* box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2); */
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 9px 20px;
  text-decoration: none;
  font-size: 12px;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {background-color: #1ea9c9;}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  /* background-color: #2980b9; */
}
.person-icon-div{
  background-color: #fa5004;
  border-radius: 17px;
  color:black;
  height: 28px;
  width: 28px;

}
.p-icon{
 /* font-size: 15px; */
}
