table {
    width: 100%;
    border: solid 1px white;
    white-space: nowrap;
  }
  .table{
    overflow-y: scroll;
  }
  thead {
    background-color: rgba(0, 0, 0, 0.2);
    color: rgba(255, 255, 255, 0.8);
    white-space: nowrap;
  }
  th {
    color: white;
    padding: 0.7rem;
    font-size: 1rem;
    max-width: 300px;
    border: 2px solid green;
    text-align: center !important;
  }
  td {
    padding: 4px;
    color: white;
    font-size: 1.1rem;
    text-align: center;
    border: 2px solid green;
  }
  
  tr {
    border:2px solid #008000 !important;
  }
  
  .table_main {
    overflow-x: scroll;
  }
  .table_main::-webkit-scrollbar {
    width: 0px;
  }
  .table_tr {
    line-height: 2.5rem !important;
  }
  